
import { createSlice } from '@reduxjs/toolkit';

// const API_URL = '/api/data/ticket/TicketData';

const initialState = {
  tickets: [],
  currentFilter: 'total_tickets',
  ticketSearch: '',
};

export const TicketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    getTickets: (state, action) => {
      state.tickets = action.payload;
    },
    setVisibilityFilter: (state, action) => {
      state.currentFilter = action.payload;
    },
    SearchTicket: (state, action) => {
      state.ticketSearch = action.payload;
    },
    DeleteTicket: (state, action) => {
      const index = state.tickets.findIndex((ticket) => ticket.Id === action.payload);
      state.tickets.splice(index, 1);
    },
  },
});

export const { getTickets, setVisibilityFilter, SearchTicket, DeleteTicket } = TicketSlice.actions;

export const fetchTickets = () => async (dispatch) => {
  try {
    // const response = await axios.get(`${API_URL}`);
    const TicketData = [
      {
        Id: 1,
        ticketTitle: 'Sed ut perspiciatis unde omnis iste',
        ticketDescription:
          'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Closed',
        Label: 'danger',
        AgentName: 'Liam',
        Date: '02-12-2021',
        deleted: false,
      },
      {
        Id: 2,
        ticketTitle: 'Consequuntur magni dolores eos qui ratione',
        ticketDescription:
          'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Pending',
        Label: 'warning',
        AgentName: 'Steve',
        Date: '02-15-2020',
        deleted: false,
      },
      {
        Id: 3,
        ticketTitle: 'Exercitationem ullam corporis',
        ticketDescription:
          'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Open',
        Label: 'success',
        AgentName: 'Jack',
        Date: '02-15-2020',
        deleted: false,
      },
      {
        Id: 4,
        ticketTitle: 'Sed ut perspiciatis unde omnis iste',
        ticketDescription:
          'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Closed',
        Label: 'danger',
        AgentName: 'Steve',
        Date: '02-15-2020',
        deleted: false,
      },
      {
        Id: 5,
        ticketTitle: 'Exercitationem ullam corporis',
        ticketDescription:
          'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Closed',
        Label: 'danger',
        AgentName: 'Liam',
        Date: '02-15-2020',
        deleted: false,
      },
      {
        Id: 6,
        ticketTitle: 'Consequuntur magni dolores eos qui ratione',
        ticketDescription:
          'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Pending',
        Label: 'warning',
        AgentName: 'Jack',
        Date: '02-15-2020',
        deleted: false,
      },
      {
        Id: 7,
        ticketTitle: 'Sed ut perspiciatis unde omnis iste',
        ticketDescription:
          'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Open',
        Label: 'success',
        AgentName: 'Steve',
        Date: '02-15-2020',
        deleted: false,
      },
      {
        Id: 8,
        ticketTitle: 'Consequuntur magni dolores eos qui ratione',
        ticketDescription:
          'ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos',
        Status: 'Closed',
        Label: 'danger',
        AgentName: 'John',
        Date: '02-1-2021',
        deleted: false,
      },
    ];
    
    dispatch(getTickets(TicketData));
  } catch (err) {
    throw new Error(err);
  }
};

export default TicketSlice.reducer;
