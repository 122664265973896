import { createSlice } from '@reduxjs/toolkit';
import http from '../../services/HttpClient';

const initialState = {
  items: [],
};

/**
 * DID-2.3.1, DID-2.3.2, DID-2.3.3
 * @description
 * Role reducer implementation
 */
export const roleSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRoleData: (state, action) => {
      state.items = action.payload;
    },
    addRole: (state, action) => {
      state.items.push(action.payload);
    },
    updateRoleList: (state, action) => {
      const idx = state.items.findIndex((val) => val._id === action.payload._id);
      state.items[idx] = action.payload;
    },
  },
});

export const { setRoleData, updateRoleList, addRole } = roleSlice.actions;

//fetches all the roles
export const fetchRoles = () => async (dispatch) => {
  try {
    const response = await http.get('/api/nfc/v1/role/listRole');
    // console.log(response.data.response.roles);
    dispatch(setRoleData(response.data.response));
  } catch (err) {
    console.log(err);
    // throw new Error(err);
  }
};

//update an existing role
export const updateRole = (role) => async (dispatch) => {
  try {
    const response = await http.post('/api/nfc/v1/role/updateRole', role);
    dispatch(updateRoleList(response.data.response.role));
  } catch (err) {
    throw new Error(err);
  }
};

//create a new role
export const saveRole = (role) => async (dispatch) => {
  try {
    const response = await http.post('/api/nfc/v1/role/createRole', role);
    dispatch(updateRoleList(response.data.response.role));
  } catch (err) {
    throw new Error(err);
  }
};

export default roleSlice.reducer;
