import React, { useState, useEffect } from 'react';
import {
  Button,
  Label,
  FormGroup,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Alert,
  Input,
} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector } from 'react-redux';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthLogo from '../../layouts/logo/AuthLogo';
import { ReactComponent as LeftBg } from '../../assets/images/bg/login-bgleft.svg';
import { ReactComponent as RightBg } from '../../assets/images/bg/login-bg-right.svg';
import UserAction from '../../store/action/UserAction';
import img1 from '../../assets/images/users/user4.jpg';

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Email is invalid').required('Email is required'),
  name: Yup.string().required('Username is required'),
});
const ShowAlert = (prop) => {
  if (prop.data?.errorFlag) {
    return <Alert color="danger">{prop.data.errorMessage}</Alert>;
  }
  return null;
};
function ForgetPassword() {
  const [isemailFormSumitted, setisemailFormSumitted] = useState(false);

  // const [email, setEmail] = useState('');
  const [counter, setCounter] = useState(60);
  const [otp, setOtp] = useState('');
  const [email, setEmail] = useState('');
  const [confirmNewPassword, setComfrmNewPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const dispatch=useDispatch();
  const navigate=useNavigate();

  const userError = useSelector((state) => {
    if (Object.keys(state.user.errors).length > 0) {
      return {
        errorFlag: true,
        errorMessage: state.user.errors.message,
      };
    }
    if (Object.keys(state.user.errors).length === 0 && Object.keys(state.user.otp).length > 0) {
      if (state.user.otp.isActive === 'Active') {
        navigate('/auth/login');
      }
      return state.user.otp;
    }

    return null;
  });
  const setTimeCounter = () => {
   const timer= setInterval(() => {
      if (counter >0) {
        setCounter((previous) => {
          if(previous>0)
          return previous - 1;
          clearInterval(timer)
          return null
        });
      }
    }, 1000);
  };
  const sendOtp = (fields) => {
    setEmail(fields.email);
    dispatch(UserAction.sendOtpMail({ email: fields.email }));
  };
  const resendOtp = () => {
    setCounter(60);
    dispatch(UserAction.sendOtpMail({ email }));
    setTimeCounter();
  };
  const OtpTimerComponent = (prop) => {
    if (!prop.data) {
      return <Label onClick={() => resendOtp()}> Resend OTP</Label>;
    }
    return <Label>OTP is valid for 00:{prop.data}</Label>;
  };

  useEffect(() => {
    if (isemailFormSumitted) {
   
      setTimeCounter();
      
    }

    return () => {};
  }, [isemailFormSumitted]);
  const changePassword = () => {
    dispatch(
      UserAction.verifyMail({
       email,
        newPassword,
        confirmPassword: confirmNewPassword,
     otp,
      }),
    );
   
  };



  if (!isemailFormSumitted)
    return (
      <div className="loginBox">
        <LeftBg className="position-absolute left bottom-0" />
        <RightBg className="position-absolute end-0 top" />
        <Container fluid className="h-100">
          <Row className="justify-content-center align-items-center h-100">
            <Col lg="3" className="loginContainer">
              <AuthLogo />
              <Card>
                <CardBody className="p-4 m-1">
                  <div className="text-center">
                    <img src={img1} alt="avatar" className="rounded-circle" width="95" />
                    
                  </div>
                  <Formik
                    initialValues={{
                      email: '',
                      name: '',
                      otp: '',
                      new: '',
                      confirm: '',
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(fields) => {
                      setisemailFormSumitted(true);
                       sendOtp(fields);
                    }}
                    render={({ errors, touched }) => (
                      <Form className="mt-3">
                        <FormGroup>
                          <Label htmlFor="name">Name</Label>
                          <Field
                            name="name"
                            type="text"
                            className={`form-control${
                              errors.name && touched.name ? ' is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage name="name" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="email">Email</Label>
                          <Field
                            name="email"
                            type="text"
                            className={`form-control${
                              errors.email && touched.email ? ' is-invalid' : ''
                            }`}
                          />
                          <ErrorMessage name="email" component="div" className="invalid-feedback" />
                        </FormGroup>
                        <FormGroup>
                          <Button type="submit" color="info" block className="me-2">
                            Reset
                          </Button>
                        </FormGroup>
                      </Form>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <ShowAlert data={userError} /> */}
        </Container>
      </div>
    );
  return (
    <div className="loginBox">
      <LeftBg className="position-absolute left bottom-0" />
      <RightBg className="position-absolute end-0 top" />
      <Container fluid className="h-100">
        <Row className="justify-content-center align-items-center h-100">
          <Col lg="3" className="loginContainer">
            <AuthLogo />
            <Card>
              <CardBody className="p-4 m-1">
                <div className="text-center">
                  <img src={img1} alt="avatar" className="rounded-circle" width="95" />
                
                 
                </div>

                {/* <p>OTP is valid till {counter} sec.</p> */}
                <OtpTimerComponent data={counter}/>
                <form className="mt-3">
                  <FormGroup>
                    <Label htmlFor="name">OTP</Label>
                    {/* <input onChange={} */}
                    <Input
                      name="otp"
                      type="text"
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                      className="form-control"
                    />
                  </FormGroup>
                  {/* <OtpTimerComponent data={counter} /> */}

                  <FormGroup>
                    <Label htmlFor="email">New Password</Label>
                    <Input
                      name="new"
                      type="text"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="email">Confirm Password</Label>
                    <Input
                      name="otp"
                      type="text"
                      value={confirmNewPassword}
                      onChange={(e) => setComfrmNewPassword(e.target.value)}
                      className="form-control"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      onClick={changePassword}
                      type="button"
                      color="info"
                      block
                      className="me-2"
                    >
                      Submit
                    </Button>
                  </FormGroup>
                </form>
              </CardBody>
            
            </Card>
          </Col>
        </Row>
        <ShowAlert data={userError} />
      </Container>
    </div>
  );
}

export default ForgetPassword;
