const initialState = {
 header: '',
};

const headerReducer = (state = initialState, action) => {

  const {
    type,
    payload
  } = action;

  switch (type) {
    case 'UPDATE_HEADER':
      // console.log(payload);

      return {
        ...state,
        header: payload.header,
      };
    default:
      return state;
  }
};
export default headerReducer;