import { combineReducers } from 'redux';
import tagReducer from './TagReducer';
import CustomizerReducer from './CustomizerSlice';
import TicketReducer from './TicketSlice';
import UserReducer from './UserReducer';
import RoleReducer from './RoleSlice';
import ScanReportReducer from './ScanReportReducer';
import SettingReducer from './SettingReducer';

import AccessSlice from './AccessSlice';
import ScanListReducer from './ScanListReducer';
import DashboardSlice from './DashboardSlice';
import AlertTagReducer from './AlertTagReducer';
import headerReducer from './headerReducer';
import ChartReducer from './ChartsReducer';

//Combine All the reducers here
const rootReducer = combineReducers({
  customizer: CustomizerReducer,
  tags: tagReducer,
  ticketReducer: TicketReducer,
  scanReport: ScanReportReducer,
  user: UserReducer,
  setting: SettingReducer,
  // userRegistration:UserReducer.userRegistrationReducer
  role: RoleReducer,
  access: AccessSlice,
  scanData: ScanListReducer,
  dashboard: DashboardSlice,
  alertTag: AlertTagReducer,
  headerReducer,
  chartData: ChartReducer
});
export default rootReducer;
