import { 
    GET_ACTIVE_TAG_DATA_FOR_CHARTS,
    GET_CHART_DATA_FOR_USER_BY_LOCATION,
    GET_CHART_DATA_FOR_USER_BY_OS,
    GET_CHART_DATA_FOR_USER_BY_ROLE,
    GET_CHART_DATA_FOR_USER_BY_TIME,
    GET_FLOW_OF_MATERIAL_DISTRIBUTION_DATA,
    GET_SCANNED_TAG_DATA_FOR_CHARTS,
    GET_SCANS_GROUPED_BY_UNIDENTIFIED_CATEGORIES,
    GET_TAG_DATA_BY_LOCATION_FOR_CHARTS,
    GET_TAG_GROUP_BY_GMID_DATA,
    GET_VERIFIED_AND_UNVERIFIED_CHART_DATA,
    GET_SCANS_BY_LOCATION_FOR_HEATMAP,
    SET_ERROR,
    GET_DASHBOARD_COUNTER
} from "../constant/types";

const initialAuthState= {};

const ChartReducer = (state=initialAuthState, action) => {
    switch (action.type) {
        case GET_ACTIVE_TAG_DATA_FOR_CHARTS:
            return {
                ...state,
                activeTagDataForCharts: action.payload.activeTagDataForCharts
            }
        case GET_CHART_DATA_FOR_USER_BY_LOCATION:
            return {
                ...state,
                chartDataForUserByLocation: action.payload.chartDataForUserByLocation
            }
        case GET_CHART_DATA_FOR_USER_BY_OS:
            return {
                ...state,
                chartDataForUserByOS: action.payload.chartDataForUserByOS
            }
        case GET_CHART_DATA_FOR_USER_BY_ROLE:
            return {
                ...state,
                chartDataForUserByRole: action.payload.chartDataForUserByRole
            }
        case GET_CHART_DATA_FOR_USER_BY_TIME:
            return {
                ...state,
                chartDataForUserByTime: action.payload.chartDataForUserByTime,
                chartDataForUserByTimeClusteredBar: action.payload.chartDataForUserByTimeClusteredBar,
                numberOfScansPerUser: action.payload.numberOfScansPerUser
            }
        case GET_FLOW_OF_MATERIAL_DISTRIBUTION_DATA:
            return {
                ...state,
                flowOfMaterialData: action.payload.flowOfMaterialData
            }
        case GET_SCANS_GROUPED_BY_UNIDENTIFIED_CATEGORIES:
            return {
                ...state,
                scansGroupedByUnidentifiedCategories: action.payload.scansGroupedByUnidentifiedCategories
            }
        case GET_SCANNED_TAG_DATA_FOR_CHARTS:
            return {
                ...state,
                scannedTagDataForCharts: action.payload.scannedTagDataForCharts
            }
        case GET_TAG_DATA_BY_LOCATION_FOR_CHARTS:
            return {
                ...state,
                tagDataByLocationForPieCharts: action.payload.tagDataByLocationForPieCharts,
                tagDataByLocationForLineCharts: action.payload.tagDataByLocationForLineCharts
            }
        case GET_TAG_GROUP_BY_GMID_DATA:
            return {
                ...state,
                tagGroupByGMIDPie: action.payload.tagGroupByGMIDPie,
                tagGroupByGMIDLine: action.payload.tagGroupByGMIDLine
            }
        case GET_VERIFIED_AND_UNVERIFIED_CHART_DATA:
            return {
                ...state,
                scansByVerifiedAndUnverified: action.payload.scansByVerifiedAndUnverified,
                dataByVerifiedScansIssueType: action.payload.dataByVerifiedScansIssueType,
                dataByUnverifiedScansIssueType: action.payload.dataByUnverifiedScansIssueType,
            }
        case GET_SCANS_BY_LOCATION_FOR_HEATMAP:
            return {
                ...state,
                scansByLocationData: action.payload.scansByLocationData
            }
        case GET_DASHBOARD_COUNTER:
            return {
                ...state,
                totalUserCount: action.payload.totalUserCount,
                scanCount: action.payload.scanCount,
                scannedTagCount: action.payload.scannedTagCount,
                unscannedTagCount: action.payload.unscannedTagCount,
                activatedTagCount: action.payload.activatedTagCount,
                unactivatedTagCount: action.payload.unactivatedTagCount,
            }
        case SET_ERROR:
            return {
                ...state,
                items: [],
                errors: action.payload?.message,
            }
        default:
           return state;
       }
  }


export default ChartReducer;
