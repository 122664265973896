import { createSlice } from '@reduxjs/toolkit';
import http from '../../services/HttpClient';

const initialState = {
  items: [],
  userAccess: [],
  isUserAccessListFetched: null
};

/**
 * DID-2.3.1, DID-2.3.2, DID-2.3.3
 * @description
 * User access reducer
 */
export const accessSlice = createSlice({
  name: 'access',
  initialState,
  reducers: {
    setAccessData: (state, action) => {
      state.items = action.payload;
    },

    setUserAccess: (state, action) => {
      state.userAccess = action.payload;
      state.isUserAccessListFetched = !!action.payload.length;
    },
  },
});

export const { setAccessData, setUserAccess } = accessSlice.actions;

export const fetchAccessList = () => async (dispatch) => {
  try {
    const response = await http.get('/api/nfc/v1/role/getAccessList');
    dispatch(setAccessData(response.data.response));
  } catch (err) {
    console.log(err);
    // throw new Error(err);
  }
};

export const fetchUserAccessList = () => async (dispatch) => {
  try {
    const response = await http.get('/api/nfc/v1/role/getUserAccessList');
    dispatch(setUserAccess(response.data.response.access));
  } catch (err) {
    // throw new Error(err);
  }
};

export default accessSlice.reducer;
