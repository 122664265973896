import React, { Suspense,useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector,useDispatch } from 'react-redux';

import Themeroutes from './routes/Router';
import ThemeSelector from './layouts/theme/ThemeSelector';
import Loader from './layouts/loader/Loader';
import { fetchUserAccessList } from './store/reducer/AccessSlice';
import { fetchRoles } from './store/reducer/RoleSlice';
import LocalStorageService from './services/localStorage';
// import {getUserStatus} from './store/action/UserAction'


const App = () => {


   
  
  const direction = useSelector((state) => state.customizer.isRTL);
  const isMode = useSelector((state) => state.customizer.isDark);
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(() => {
     const localStorageService = LocalStorageService.getService();
     return localStorageService.isAuthenticated();
    // if(Cookies.get('token')===undefined){
    //   console.log(Cookies.get('token'));
    //   return false
    // }
    // return true
  });
  // const isAuthenticated=true;

  const routing = useRoutes(Themeroutes(isAuthenticated));
  



  useEffect(() => {
    if (isAuthenticated) {
      dispatch(fetchUserAccessList());
      dispatch(fetchRoles());
    }
  }, [isAuthenticated]);
  


  return (
    <Suspense fallback={<Loader />}>
      <div
        className={`${direction ? 'rtl' : 'ltr'} ${isMode ? 'dark' : ''}`}
        dir={direction ? 'rtl' : 'ltr'}
      >
        <ThemeSelector />
        { routing}
      </div>
    </Suspense>
  );
};

export default App;
