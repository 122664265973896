import { GET_TAG,SAVE_TAG,SET_ERROR,CHANGE_TAG_STATUS, UPDATE_TAG,GET_MANUFACTURER,GET_GMID } from "../constant/types";

const initialAuthState= {
 items:[],
 errors:{}
};

// Slice reducer tagReducer, gets only user object from whole state object
const tagReducer = (state=initialAuthState, action) => {
    switch (action.type) {
        case GET_TAG:
            return{
                 ...state,
                   items: action.payload?.tags,
                   errors:{}
            }
            case GET_MANUFACTURER:
             
              return{
                   ...state,
                     items: action.payload?.manufacturer,
                     errors:{}
              }
              case GET_GMID:
             
                return{
                     ...state,
                       items: action.payload?.gmid,
                       errors:{}
                }
            case SAVE_TAG:
              if(action.payload?.tags.successMsg) {
                // alert(action.payload?.tags.successMsg);
              }
              else {
                // alert(action.payload?.tags.errorMsg);
                return state;
              }
              return{
                   ...state,
                     items: action.payload?.tags.response.tag,
                     errors:{}
              }
              case SET_ERROR:
                return {
                  ...state,
                  items: [],
                  errors: action.payload?.message,
                }
              case CHANGE_TAG_STATUS:
                return {
                  ...state,
                  items: action.payload?.tags,
                  errors: {},
                }
              case UPDATE_TAG:
                if(action.payload?.tag.successMsg) {
                  // alert(action.payload?.tag.successMsg);
                }
                else {
                  // alert(action.payload?.tag.errorMsg);
                }
                return state;
         default:
           return state;
       }
  }


export default tagReducer;
