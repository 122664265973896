import { GET_REPORT, GET_REPORTS, UPDATE_REPORT } from "../constant/types";

const initialState= {
  scanReports: []
};

const ScanReportReducer = (state=initialState, action) => {
  switch (action.type) {
    case GET_REPORTS:
      return{
        ...state,
        scanReports: action.payload?.reports.response.scanReportData,
        totalcount:action.payload?.reports.response.totalcount,
        openReportsCount:action.payload?.reports.response.openReportsCount,
        closedReportsCount:action.payload?.reports.response.closedReportsCount,
        pendingReportsCount:action.payload?.reports.response.pendingReportsCount,
      }
    case GET_REPORT:
      return{
        ...state,
        reportDetail: action.payload?.report,
      }
    case UPDATE_REPORT:
      return{
        ...state,
        history: action.payload?.history,
      }
    default:
      return state;
  }
}

export default ScanReportReducer;
