import {USER_REGISTRATION, SET_ERROR, USER_AUTHENTICATION, UPDATE_USER,GET_USER,CHANGE_USER_STATUS, SEND_OTP,VERIFY_OTP ,USER_LOGOUT,RESET_STATE} from "../constant/types";

const initialAuthState = {
  items: [],
  errors: {},
  otp:[],
  otpError:{},
  isAuthenticated:false
};
// Slice reducer tagReducer, gets only user object from whole state object
const userReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case USER_AUTHENTICATION:
      return {
        ...state,
        items: action.payload?.user,
        errors: {},
        isAuthenticated:true
      }
    case USER_REGISTRATION:
      return {
        ...state,
        items: [],
        errors: {}
      }
    case UPDATE_USER:
      return {
        ...state,
        items: action.payload?.user,
        errors: {}
      }
    case GET_USER:
      return {
        ...state,
        items: action.payload?.user,
        errors: {}
      }
      case CHANGE_USER_STATUS:
      return {
        ...state,
        items: action.payload?.user,
        errors: {}
      }
      case SEND_OTP:
      return {
        ...state,
        otp: action.payload?.user,
        otpError: {},
        errors:{}
      }
      case RESET_STATE:
        return {
          items:[],
          errors:{}
        }
        
      case VERIFY_OTP:
      return {
        ...state,
        otp: action.payload?.user,
        otpError: {},
        errors:{}
      }
    case USER_LOGOUT:
      state.isAuthenticated=action.payload?.user
      state.items=[]
    state.errors= {}
    state.otp=[]
    state.otpError={}
      return state
    case SET_ERROR:
      return {
        ...state,
        items: [],
        errors: {message:action.payload.message},
      }
    default:
      return state;
  }
}





export default userReducer

