import { GET_ALERT_TAG_LIST } from "../constant/types";

const initialState= {
  alertTagList: [],
  totalcount: ''
};

const AlertTagReducer = (state=initialState, action) => {
  switch (action.type) {
    case GET_ALERT_TAG_LIST:
      return{
        ...state,
        alertTagList: action.payload?.alertTagList,
        totalcount: action.payload?.totalcount
      }
    default:
      return state;
  }
}

export default AlertTagReducer;
