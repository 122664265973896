/* eslint-disable class-methods-use-this */
import store from '../store/Store';

/**
 * DID-2.3.1, DID-2.3.2, DID-2.3.3
 * @description
 * This class implements the methods to check the user permission
 * for a given UI elements (routes/components)
 */
class PermissionService {
  /**
   * @method Public
   * @description Fetch the user access from store
   */
  getCurrentUserAccess() {
    return store.getState().access.userAccess;
  }

  /**
   * @method Public
   * @param {string[]} currentUserRoles
   * @param {string} requiredRole
   *
   */
  verifyRole(currentUserAccess, requiredAccess) {
    //check if user roles have the required access
    // eslint-disable-next-line consistent-return
    for (let i = 0; i < requiredAccess.length; i++) {
      const access = requiredAccess[i];
      if (!currentUserAccess.includes(access)) {
        return false;
      }
    }
    return true;
  }

  /**
   *
   * @param {string[]} requiredAccess
   * @returns {boolean}
   * @description Check if user can access the given element or not based on required access and user role
   */
  can(requiredAccess) {
    const currentUserRoles = this.getCurrentUserAccess();
    const hasAccess = this.verifyRole(currentUserRoles, requiredAccess);
    return hasAccess;
  }
}

export default PermissionService;
